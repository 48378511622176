import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as ext from "../api/Extensions";
import * as api from "../api/ApiClient";
import { getDeepErrorMessage } from '../utils/Utils';

export interface IModelsStore {
    IsBusy: boolean,
    HierarchyMembers: api.MemberInfo[] | null,
    SecurityProfiles: string[] | null,
    Models: api.ModelInfo[] | null,
    Dimensions: api.DimensionInfo[] | null,
    DimensionProperties: api.PropertyInfo[] | null,
    MemberProperties: api.MemberPropertyInfo[] | null,
    MemberTranslations: api.MemberTranslationInfo[] | null,
    SelectedModel: api.ModelInfo | null,
    SelectedDimension: api.DimensionInfo | null,
    SelectedRelatedDimension: api.DimensionInfo | null,
    SelectedHierarchy: string | null,
    SelectedMember: api.MemberInfo | null,
    IsReorderModeActive: boolean | false,
    IsAlternateHier: boolean | false,
    SelectedAlternateMember: api.MemberInfo | null,
    SelectedSubset: api.SubsetInfo | null,
    SelectedList: api.ListInfo | null,
    Lists: api.ListInfo[] | null,
    ListDetails: api.ListDetailsInfo[] | null,
    ListPropertyDetails: api.ListPropertyDetailsInfo[] | null,
    ListsContents: { [key: string]: { [key: string]: any }[] } | null,
    ShowAllColumns: boolean | true,
    InsideModelDetailsView: boolean | false,
    IsAddNewDimension: boolean | false,
    IsAddNewModel: boolean | false,
    IsAddNewModelView: boolean | false,
    IsAddNewSubset: boolean | false,
    IsAddMemberToSubset: boolean | false,
    IsAddNewList: boolean | false,
    CutCopyMember: api.MemberInfo | null,
    MemberDraggedFrom: api.MemberInfo | null;
    IsCutCopy: boolean | false,
    AllMembers: api.MemberInfo[] | null,
    MemberSelectorNameOnlyLocal: boolean | false,
    MemberSelectorCaptionOnlyLocal: boolean | false,
    AllMembersKeyToParentKey: Map<number, number | null> | null,
    AllAlternateHierarchyMembers: api.MemberInfo[] | null,
    DimensionColumnPreference: [] | null,
    IsValidated: boolean | null,
    ValidationError: string | null,
    StandardProperties: api.StandardAttributeInfo[] | null,
    DimensionRoleInfo: api.DimensionRoleInfo[] | null,
    AutoExpandChildren: boolean | null,
    Subsets: api.SubsetInfo[] | null,
    SelectedRole: string | null,
    DimensionASSecurityProfiles: string[] | null,
    DimensionMemberHierarchyMembers: api.MemberInfo[] | null,
    SelectedModelView: api.ModelViewInfo | null,
    SelectedModelViewDimension: api.ModelViewDimensionInfo | null,
    AllModelViews: api.ModelViewInfo[] | null,
    FilteredMembers: api.MemberInfo[] | null,
    HighlightedMember: api.MemberInfo | null,
    SelectorMember: api.MemberInfo | null,
    SelectedDimensionName: string | null;
}

const ModelsStore: Module<IModelsStore, any> = {
    namespaced: true,
    state: {
        IsBusy: false,
        HierarchyMembers: null,
        SecurityProfiles: null,
        Models: null,
        Dimensions: null,
        DimensionProperties: null,
        MemberProperties: null,
        MemberTranslations: null,
        SelectedModel: null,
        SelectedDimension: null,
        SelectedRelatedDimension: null,
        SelectedHierarchy: null,
        SelectedMember: null,
        IsAlternateHier: false,
        IsReorderModeActive: false,
        SelectedAlternateMember: null,
        SelectedSubset: null,
        SelectedList: null,
        Lists: null,
        ListDetails: null,
        ListPropertyDetails: null,
        ListsContents: null,
        ShowAllColumns: true,
        InsideModelDetailsView: false,
        IsAddNewDimension: false,
        IsAddNewModel: false,
        IsAddNewModelView: false,
        IsAddNewSubset: false,
        IsAddMemberToSubset: false,
        IsAddNewList: false,
        CutCopyMember: null,
        MemberDraggedFrom: null,
        IsCutCopy: false,
        AllMembers: null,
        MemberSelectorNameOnlyLocal: false,
        MemberSelectorCaptionOnlyLocal: false,
        AllMembersKeyToParentKey: null,
        AllAlternateHierarchyMembers: null,
        DimensionColumnPreference: null,
        IsValidated: null,
        ValidationError: null,
        StandardProperties: null,
        DimensionRoleInfo: null,
        AutoExpandChildren: null,
        Subsets: null,
        SelectedRole: null,
        DimensionASSecurityProfiles: null,
        DimensionMemberHierarchyMembers: null,
        SelectedModelView: null,
        SelectedModelViewDimension: null,
        AllModelViews: null,
        FilteredMembers: null,
        HighlightedMember: null,
        SelectorMember: null,
        SelectedDimensionName: null
    },
    mutations: {
        SetIsBusy(state, isBusy: boolean) {
            state.IsBusy = isBusy;
        },
        SetHierarchyMembers(state: any, hierarchyMembers: any[]) {
            state.HierarchyMembers = hierarchyMembers;
        },
        SetSecurityProfiles(state: any, profiles: string[]) {
            state.SecurityProfiles = profiles;
        },
        SetModels(state: any, models: api.ModelInfo[]) {
            state.Models = models;
        },
        SetActiveModels(state: any, activeModels: api.ModelInfo[]) {
            state.ActiveModels = activeModels;
        },
        SetStandardProperties(state: any, standardAttributes: api.StandardAttributeInfo[]) {
            state.StandardProperties = standardAttributes;
        },
        SetDimensionRoleInfo(state: any, dimensionRoleInfo: api.DimensionRoleInfo[]) {
            state.DimensionRoleInfo = dimensionRoleInfo;
        },
        SetSelectedModel(state: any, model: api.ModelInfo) {
            state.SelectedModel = model;
        },
        SetSelectedDimension(state: any, dimension: api.DimensionInfo) {
            state.SelectedDimension = dimension;
        },
        SetSelectedRelatedDimension(state: any, dimension: api.DimensionInfo) {
            state.SelectedRelatedDimension = dimension;
        },
        SetDimensions(state: any, dimensions: api.DimensionInfo[]) {
            dimensions.sort((a, b) => a.dimensionName!.localeCompare(b!.dimensionName!));

            state.Dimensions = dimensions;
        },
        SetAllDimensions(state: any, dimensions: api.DimensionInfo[]) {
            dimensions.sort((a, b) => a.dimensionName!.localeCompare(b!.dimensionName!));

            state.AllDimensions = dimensions;
        },
        SetDeletedDimensions(state: any, deletedDimensions: api.DimensionInfo[]) {
            state.DeletedDimensions = deletedDimensions;
        },
        SetActiveDimensions(state: any, activeDimensions: api.DimensionInfo[]) {
            state.ActiveDimensions = activeDimensions;
        },
        SetSelectedDimensionProperties(state: any, properties: api.PropertyInfo[]) {
            state.DimensionProperties = properties;
        },
        SetSelectedHierarchy(state: any, hierarchy: string) {
            state.SelectedHierarchy = hierarchy;
        },
        SetSelectedMember(state: any, member: api.MemberInfo) {
            state.SelectedMember = member;
        },
        SetIsReorderModeActive(state: any, isReorderModeActive: boolean) {
            state.IsReorderModeActive = isReorderModeActive;
        },
        SetIsAlternateHier(state: any, isAlternate: boolean) {
            state.IsAlternateHier = isAlternate;
        },
        SelectedAlternateMember(state: any, member: api.MemberInfo) {
            state.SelectedAlternateMember = member;
        },
        SetSelectedMemberProperties(state: any, properties: api.MemberPropertyInfo[]) {
            state.MemberProperties = properties;
        },
        SetSelectedMemberTranslations(state: any, translations: api.MemberTranslationInfo[]) {
            state.MemberTranslations = translations;
        },
        SetShowAllColumns(state: any, showAll: boolean) {
            state.ShowAllColumns = showAll;
        },
        SetIsInsideModelDetailsView(state: any, insideModelDetailsView: boolean) {
            state.InsideModelDetailsView = insideModelDetailsView;
        },
        SetIsAddNewDimension(state: any, isAddNewDimension: boolean) {
            state.IsAddNewDimension = isAddNewDimension;
        },
        SetIsAddNewModel(state: any, isAddNewModel: boolean) {
            state.IsAddNewModel = isAddNewModel;
        },
        SetIsAddNewModelView(state: any, isAddNewModelView: boolean) {
            state.IsAddNewModelView = isAddNewModelView;
        },
        SetIsAddNewSubset(state: any, isAddNewSubset: boolean) {
            state.IsAddNewSubset = isAddNewSubset;
        },
        SetIsAddMemberToSubset(state: any, isAddMemberToSubset: boolean) {
            state.IsAddMemberToSubset = isAddMemberToSubset;
        },
        SetIsAddNewList(state: any, isAddNewList: boolean) {
            state.IsAddNewList = isAddNewList;
        },
        SetCutCopyMember(state: any, member: api.MemberInfo) {
            state.CutCopyMember = member;
        },
        SetMemberDraggedFrom(state: any, member: api.MemberInfo) {
            state.MemberDraggedFrom = member;
        },
        SetIsCutCopy(state: any, isCutCopy: boolean) {
            state.IsCutCopy = isCutCopy;
        },
        SetAllMembers(state: any, allMembers: api.MemberInfo[]) {
            state.AllMembers = allMembers;
        },
        AddToAllMembers(state: any, member: api.MemberInfo) {
            if (state.AllMembers == null) {
                state.AllMembers = [];
            }
            state.AllMembers.push(member);
        },
        SetMemberSelectorNameOnlyLocal(state: any, memberSelectorNameOnlyLocal: boolean) {
            state.MemberSelectorNameOnlyLocal = memberSelectorNameOnlyLocal;
        },
        SetMemberSelectorCaptionOnlyLocal(state: any, memberSelectorCaptionOnlyLocal: boolean) {
            state.MemberSelectorCaptionOnlyLocal = memberSelectorCaptionOnlyLocal;
        },
        SetAllMembersKeyToParentKey(state: any, allMembersKeyToParentKey: Map<number, number | null>) {
            state.AllMembersKeyToParentKey = allMembersKeyToParentKey;
        },
        AddToAllMembersKeyToParentKey(state: any,  { key, parentKey }) {
            if (state.AllMembersKeyToParentKey == null) {
                state.AllMembersKeyToParentKey = new Map<number, number | null>();
            }
            state.AllMembersKeyToParentKey.set(key, parentKey);
        },
        SetAllAlternateHierarchyMembers(state: any, allAlternateMembers: api.MemberInfo[]) {
            state.AllAlternateHierarchyMembers = allAlternateMembers;
        },
        SetDimensionColumnPreference(state: any, cookieObject: any[]) {
            state.DimensionColumnPreference = cookieObject;
        },
        SetIsValidated(state: any, isValidated: boolean) {
            state.IsValidated = isValidated;
        },
        SetValidationError(state: any, validationError: string) {
            state.ValidationError = validationError;
        },
        SetAutoExpandChildren(state: any, expand: boolean) {
            state.AutoExpandChildren = expand;
        },
        SetSelectedRole(state: any, role: string) {
            state.SelectedRole = role;
        },
        SetSubsets(state: any, subsets: api.SubsetInfo[]) {
            state.Subsets = subsets;
        },
        SetSelectedSubset(state: any, subset: api.SubsetInfo) {
            state.SelectedSubset = subset;
        },
        SetSelectedList(state: any, list: api.ListInfo) {
            state.SelectedList = list;
        },
        SetLists(state: any, lists: api.ListInfo[]) {
            state.Lists = lists;
        },
        SetListDetails(state: any, listDetails: api.ListDetailsInfo[]) {
            state.ListDetails = listDetails;
        },
        SetListPropertyDetails(state: any, listPropertyDetails: api.ListPropertyDetailsInfo[]) {
            state.ListPropertyDetails = listPropertyDetails;
        },
        SetListsContents(state: any, listsContents: { [key: string]: { [key: string]: any }[] }) {
            state.ListsContents = listsContents;
        },
        SetDimensionASSecurityProfiles(state: any, profiles: string[]) {
            state.DimensionASSecurityProfiles = profiles;
        },
        SetDimensionMemberHierarchyMembers(state: any, allMembers: api.MemberInfo[]) {
            state.DimensionMemberHierarchyMembers = allMembers;
        },
        SetSelectedModelView(state: any, modelView: api.ModelViewInfo) {
            state.SelectedModelView = modelView;
        },
        SetSelectedModelViewDimension(state: any, modelViewDimension: api.ModelViewDimensionInfo) {
            state.SelectedModelViewDimension = modelViewDimension;
        },
        SetAllModelViews(state: any, modelViews: api.ModelViewInfo[]) {
            state.AllModelViews = modelViews;
        },
        SetFilteredMembers(state: any, members: api.MemberInfo[]) {
            state.FilteredMembers = members;
        },
        SetHighlightedMember(state: any, member: api.MemberInfo) {
            state.HighlightedMember = member;
        },
        SetSelectorMember(state: any, member: api.MemberInfo) {
            state.SelectorMember = member;
        },
        SetSelectedDimensionName(state: any, dimension: string) {
            state.SelectedDimensionName = dimension;
        }
    },
    actions: {
        // Scaled down version that only loads models and security profiles
        async LoadModels(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var securityClient = new api.SecurityClient(auth_client);
            var client1 = new api.MetadataClient(auth_client);
            var models = await client1.getModels();
            var securityProfiles = await securityClient.getDimensionSecurityProfiles();
            models.sort((a, b) => a.modelName!.localeCompare(b!.modelName!));
            var fixQuerySpec = function (x: api.HierarchicalSpec) {
                if (x.children == null)
                    x.children = [];
                x.children.forEach(child => {
                    fixQuerySpec(child);
                });
            }
            for (var i = 0; i < models!.length; i++) {
                if (!models[i]!.dimensions) {
                    models[i]!.dimensions = [];
                }
                if (!models[i]!.dimensionInfo) {
                    models[i]!.dimensionInfo = [];
                }
                if (!models[i]!.modelViews) {
                    models[i]!.modelViews = [];
                }
                if (models[i].modelViews != null) {
                    models[i].modelViews!.forEach(view => {
                        if (view.modelViewDimensions != null) {
                            view.modelViewDimensions!.forEach(dim => {
                                if (dim.querySpec != null)
                                    fixQuerySpec(dim.querySpec!)
                            });
                        }
                    });
                }
            }

            context.commit("SetModels", models);
            context.commit("SetSecurityProfiles", securityProfiles);
            context.commit("SetIsBusy", false);
        },
        async LoadMetadata(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var securityClient = new api.SecurityClient(auth_client);
            var client1 = new api.MetadataClient(auth_client);
            var models = await client1.getModels();
            var securityProfiles = await securityClient.getDimensionSecurityProfiles();
            models.sort((a, b) => a.modelName!.localeCompare(b!.modelName!));
            var fixQuerySpec = function (x: api.HierarchicalSpec) {
                if (x.children == null)
                    x.children = [];
                x.children.forEach(child => {
                    fixQuerySpec(child);
                });
            }
            for (var i = 0; i < models!.length; i++) {
                if (!models[i]!.dimensions) {
                    models[i]!.dimensions = [];
                }
                if (!models[i]!.dimensionInfo) {
                    models[i]!.dimensionInfo = [];
                }
                if (!models[i]!.modelViews) {
                    models[i]!.modelViews = [];
                }
                if (models[i].modelViews != null) {
                    models[i].modelViews!.forEach(view => {
                        if (view.modelViewDimensions != null) {
                            view.modelViewDimensions!.forEach(dim => {
                                if (dim.querySpec != null)
                                    fixQuerySpec(dim.querySpec!)
                            });
                        }
                    });
                }
            }

            context.commit("SetModels", models);
            context.commit("SetSecurityProfiles", securityProfiles);
            var dimensions = await client1.getDimensions();

            dimensions.sort((a, b) => a.dimensionName!.localeCompare(b!.dimensionName!));

            context.commit("SetDimensions", dimensions);

            await this.dispatch("models/LoadDimensionRoles");
            //Subsets
            var subsets = await client1.getSubsets();
            context.commit("SetSubsets", subsets);
            //Lists
            await this.dispatch("models/LoadListMetadata");
            //Dimension profiles
            await this.dispatch("models/LoadDimensionASSecurityProfiles");
            context.commit("SetIsBusy", false);
        },

        async LoadHierarchyMembers(context, dimensionName: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var hierarchyMembers = await client.getDefaultHierarchyMembers(dimensionName);
            context.commit("SetHierarchyMembers", hierarchyMembers);
        },

        async LoadStandardAttributes(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var attributes = await client.getStandardAttributes();
            context.commit("SetStandardProperties", attributes);
        },

        async LoadDimensionRoles(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var dimensionRoleInfo = await client.getDimensionRoles();
            context.commit("SetDimensionRoleInfo", dimensionRoleInfo);
        },

        async LoadAllDimensions(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client1 = new api.MetadataClient(auth_client);
            // var client2 = new api.MetadataClient(auth_client); Do we need client2?
            var allDimensions = await client1.getDimensions();
            var deletedDimensions = allDimensions.filter(dimension => dimension.processingStatus === -1);
            var activeDimensions = allDimensions.filter(dimension => dimension.processingStatus !== -1);
            context.commit("SetAllDimensions", allDimensions);
            context.commit("SetDeletedDimensions", deletedDimensions);
            context.commit("SetActiveDimensions", activeDimensions);
        },

        async LoadDimensionASSecurityProfiles(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client1 = new api.SecurityClient(auth_client);

            var profiles = await client1.getDimensionSecurityProfiles();
            context.commit("SetDimensionASSecurityProfiles", profiles);
        },

        async SaveModel(context: any, modelDetails: ext.Pair<api.ModelInfo>) {
            var modelName = modelDetails.edited.modelName;
            var managedModel = modelDetails.edited.managedModel;
            var directQueryMode = modelDetails.edited.directQueryMode;
            var editedDimensions = modelDetails.edited.dimensions;
            var models = context.state.Models;
            var dimensions = context.state.Dimensions;

            if (models != null && dimensions != null && modelName != null && managedModel != null && editedDimensions != null && directQueryMode != null) {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();

                var client = new api.MetadataClient(auth_client);

                try {
                    if (modelDetails.original === null || modelDetails.original.modelName === '') {
                        var saved = await client.createModel(modelDetails.edited);
                    }
                    else {
                        var saved = await client.updateModel(modelDetails.original.modelName!, modelDetails.edited);
                    }
                    var idx = -1;
                    for (var i = 0; i < models.length; i++) {
                        if (models[i].modelName == modelName) {
                            idx = i;
                            break;
                        }
                    }

                    if (idx > -1)
                        models.splice(idx, 1);
                    models.push(saved);
                    models.sort((a: any, b: any) => a.modelName!.localeCompare(b.modelName!));
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_saved_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModel", null);
                    context.commit("SetIsAddNewModel", false);
                    this.dispatch("models/LoadMetadata");
                    this.dispatch("models/LoadAllDimensions");

                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_could_not_be_saved") + " "
                            + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async SaveDimension(context: any, dimensionDetails: ext.Pair<api.DimensionInfo>) {
            var dimensionName = dimensionDetails.edited.dimensionName;
            var defaultMember = dimensionDetails.edited.defaultMember;
            var dimenionType = dimensionDetails.edited.dimensionType;
            var dimensionMaxDepth = '';
            var dimensionNonLeaf = dimensionDetails.edited.nonLeafDataMembers;
            var dimensionTranslations = dimensionDetails.edited.translations;

            var dimensions = context.state.Dimensions;

            if (dimensionName != null && dimensions != null) {

                var auth_client = new AuthClient();
                await auth_client.ensureToken();

                var client = new api.MetadataClient(auth_client);

                try {
                    if (dimensionDetails.original === null || dimensionDetails.original.dimensionName === '') {
                        var saved = await client.createDimension(dimensionDetails.edited);
                    }
                    else {
                        var saved = await client.updateDimension(dimensionDetails.original.dimensionName!, dimensionDetails.edited);
                    }

                    var idx = -1;
                    for (var i = 0; i < dimensions.length; i++) {
                        if (dimensions[i].dimensionName === dimensionName) {
                            idx = i;
                            break;
                        }
                    }

                    if (idx > -1)
                        dimensions.splice(idx, 1);
                    dimensions.push(saved);
                    dimensions.sort((a: any, b: any) => a.dimensionName!.localeCompare(b.dimensionName!));

                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Dimension_saved_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedDimension", saved);
                    context.commit("SetIsAddNewDimension", false);
                    this.dispatch("models/LoadMetadata");
                    this.dispatch("models/LoadAllDimensions");
                }
                catch (err) {
                    if (dimensionName.length > 32) {
                        this.dispatch("ShowToast", new api.ToastNotification({
                            message: context.rootState.Localizer.Localize("ModelsStore_Dimension_Name_too_long"),
                            type: api.ToastType.Error
                        }));
                    } else {
                        this.dispatch("ShowToast", new api.ToastNotification({
                            message: context.rootState.Localizer.Localize("ModelsStore_Dimension_could_not_be_saved") + " "
                                + getDeepErrorMessage(err),
                            type: api.ToastType.Error
                        }));
                    }
                }
            }
        },

        async SaveSubset(context: any, subsetDetails: api.SubsetInfo) {
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                var saved = await client.createSubset(subsetDetails);
                context.commit("SetIsAddNewSubset", false)
                this.dispatch("models/LoadMetadata");
                this.dispatch("models/LoadAllDimensions");
                context.commit("SetIsBusy", false);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Subset_saved_successfully"),
                    type: api.ToastType.Info
                }));
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Subset_could_not_be_saved") + " "
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
                context.commit("SetIsBusy", false);
            }
        },

        async DeleteSubset(context: any, subsetDetails: api.SubsetInfo) {
            try {
                context.commit("SetIsBusy", true);
                var saved = null;
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                //Different API calls used depending on if the default (none) hierarchy is used or an alternate hierarchy specified
                if (subsetDetails.hierarchyName!.length > 0)
                    saved = await client.deleteHierarchySubset(subsetDetails.dimensionName!, subsetDetails.hierarchyName!, subsetDetails.subsetName!);
                else
                    saved = await client.deleteSubset(subsetDetails.dimensionName!, subsetDetails.subsetName!);
                context.commit("SetIsBusy", false);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Subset_deleted"),
                    type: api.ToastType.Info
                }));
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Subset_could_not_be_deleted") + " "
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async DeleteSubsetSilently(context: any, subsetDetails: api.SubsetInfo) {
            try {
                context.commit("SetIsBusy", true);
                var saved = null;
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                //Different API calls used depending on if the default (none) hierarchy is used or an alternate hierarchy specified
                if (subsetDetails.hierarchyName!.length > 0)
                    saved = await client.deleteHierarchySubset(subsetDetails.dimensionName!,
                        subsetDetails.hierarchyName!, subsetDetails.subsetName!);
                else
                    saved = await client.deleteSubset(subsetDetails.dimensionName!, subsetDetails.subsetName!);
                context.commit("SetIsBusy", false);
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Problem_with_removing_subset") + " "
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveList(context: any, list: api.ListInfo) {
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                var listid = await client.createListDetails(list.listDetails!);
                list.listDetails!.listId = listid;
                if (list.properties != null) {
                    for (var property of list.properties!) {
                        //Create or update the property details first
                        var propertyDetails = property.propertyDetails;
                        var propertyid = await client.createListPropertyDetails(propertyDetails!);
                        //Set the property list to the right property id if this is an addition
                        propertyDetails!.propertyID = propertyid;
                        //Check for any other property details that have the same name and set them to current property id
                        list.properties.forEach(ip => {
                            if (ip.propertyDetails?.property == propertyDetails?.property && ip.propertyDetails?.propertyID != propertyDetails?.propertyID) {
                                ip.propertyDetails!.propertyID = propertyid;
                            }
                        });
                        //Make sure the property has the correct list id to match up to
                        property.listDetails!.listId = listid;
                        var propertylistid = await client.createListProperties(property);
                        if (propertylistid < 0) {
                            throw new Error();
                        }
                        property.id = propertylistid;
                    }
                }
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_SaveList_Success"),
                    type: api.ToastType.Info
                }));
                //context.commit("SetIsBusy", false);
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_SaveList_Failure")
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveListContents(context: any, listContents: ext.ListContentsDetails[]) {
            try {
                var list = context.state.SelectedList as api.ListInfo;
                if (list != null) {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.MetadataClient(auth_client);
                    var contents: { [key: string]: any }[] = [];
                    for (const content of listContents) {
                        contents.push(content.Contents);
                    }
                    await client.updateListContents(list.listDetails!.listId, contents);
                }
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_SaveList_Success"),
                    type: api.ToastType.Info
                }));
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_SaveList_Failure")
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async DeleteSelectedList(context: any, list: api.ListInfo) {
            try {
                context.commit("SetIsBusy", true);
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                await client.deleteListDetails(list.listDetails!.listId);
                context.commit("SetIsBusy", false);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_DeleteList_Success"),
                    type: api.ToastType.Info
                }));
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_DeleteList_Failure")
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async DeleteListContents(context: any, listContents: ext.ListContentsDetails[]) {
            try {
                var list = context.state.SelectedList as api.ListInfo;
                if (list != null) {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.MetadataClient(auth_client);
                    var contents: { [key: string]: any }[] = [];
                    for (const content of listContents) {
                        contents.push(content.Contents);
                    }
                    await client.deleteListContents(list.listDetails!.listId, contents);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_DeleteContents_Success"),
                        type: api.ToastType.Info
                    }));
                }
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_SaveList_Failure")
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async DeletePropertyFromList(context: any, id: number) {
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                await client.deleteListProperty(id);
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_RemoveProp_Failure")
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async DeletePropertyDetails(context: any, id: number) {
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.MetadataClient(auth_client);
                await client.deleteListPropertyDetails(id);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_DeleteProp_Success"),
                    type: api.ToastType.Info
                }));
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_DeleteProp_Failure")
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async LoadListMetadata(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client1 = new api.MetadataClient(auth_client);
            try {
                var lists = await client1.getLists();
                var properties = await client1.getListPropertyDetails();
                var listDetails: api.ListDetailsInfo[] = [];
                lists.forEach(l => { listDetails.push(l.listDetails!); });
                context.commit("SetLists", lists);
                context.commit("SetListDetails", listDetails);
                context.commit("SetListPropertyDetails", properties);
            } catch {
                // Error here means the tables aren't setup.
                // Don't notify users as this would be loaded everytime metadata is refreshed
            }
        },

        async DeleteSelectedModel(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var model = context.state.SelectedModel;
            if (model != null) {
                try {
                    await client.deleteModel(model.modelName!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_deleted_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModel", null);
                }
                catch {
                    context.commit("SetSelectedModel", null);
                    // Deleting model that isn't in the database, GUI handles this anyways, no need to notify user.
                }
            }
            this.dispatch("models/LoadMetadata");
        },

        async PublishSelectedModel(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var model = context.state.SelectedModel;
            if (model != null) {
                try {
                    await client.publishAnalysisServicesModel(model.modelName!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_published_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModel", null);
                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_could_not_be_published") + " "
                            + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async RefreshSelectedModel(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var model = context.state.SelectedModel;
            if (model != null) {
                try {
                    await client.refreshAnalysisServicesModel(model.modelName!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_refreshed_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModel", null);
                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_could_not_be_refreshed") + " "
                            + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async RefreshSecurity(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var model = context.state.SelectedModel;
            if (model != null) {
                try {
                    await client.refreshAnalysisServicesModelSecurity(model.modelName!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_refreshed_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModel", null);
                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_could_not_be_refreshed") + " "
                            + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async DeleteSelectedDimension(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var dimension = context.state.SelectedDimension;
            if (dimension != null) {
                try {
                    await client.deleteDimension(dimension.dimensionName!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Dimension_deleted_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedDimension", null);
                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Dimension_could_not_be_deleted")
                            + " " + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
            this.dispatch("models/LoadMetadata");
        },

        async DeleteSelectedProperty(context: any, propertyName: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            var dimension = context.state.SelectedDimension;
            if (dimension != null) {
                try {
                    await client.deleteProperty(dimension.dimensionName!, propertyName);
                }
                catch (err) {
                    if (getDeepErrorMessage(err) !== 'Property does not exist.') {
                        this.dispatch("ShowToast", new api.ToastNotification({
                            message: context.rootState.Localizer.Localize('ModelsStore_Property_could_not_be_deleted')
                                + " " + getDeepErrorMessage(err),
                            type: api.ToastType.Error
                        }));
                    }
                }
            }
        },

        async SaveDimensionColumnPreference(context) {
            try {
                localStorage.setItem('DimensionColumnPreference',
                    JSON.stringify(context.state.DimensionColumnPreference));
            } catch (e) {
                console.warn(e)
            }
        },

        async LoadDimensionColumnPreference(context) {
            try {
                const jsonObj = JSON.parse(localStorage.getItem('DimensionColumnPreference')!);
                context.commit('SetDimensionColumnPreference', jsonObj);
            } catch (e) {
                console.warn(e)
            }
        },

        async ProcessChanges(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            try {
                await client.processMetadata();
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Metadata_processed_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Metadata_could_not_be_processed") + " "
                        + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
            finally {
                this.dispatch("models/LoadMetadata");
                this.dispatch("models/LoadAllDimensions");
                context.commit("SetIsBusy", false);
            }
        },

        async ProcessHierarchy(context, hierarchyInfo: api.HierarchyInfo) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.MetadataClient(auth_client);
            try {
                await client.processHierarchy(hierarchyInfo.dimensionName!, hierarchyInfo.hierarchyName!)
            }
            catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: "Hierarchy could not be processed: " + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        }
    },
    getters: {
        getModelInfoByModelName: (state) =>
            (modelName: string): api.ModelInfo | null => {
                var recursiveSearch = function (name: string, model: api.ModelInfo): api.ModelInfo | null {
                    if (model != null) {
                        return model;
                    }
                    return null;
                }

                if (state.SelectedModel != null) {
                    var result = recursiveSearch(modelName, state.SelectedModel);
                    return result;
                }
                return null;
            },
        getDimensionByName: (state) =>
            (dimensionName: string): api.DimensionInfo | null => {
                if (state.Dimensions != null) {
                    return state.Dimensions!.find(d => d.dimensionName === dimensionName)!;
                }
                return null;
            }
    }
};

export default ModelsStore;
